// Add/Remove/Sync this polyfills list based on supported/outdated browsers
(async function addPolyfills() {
  // Sync this list with legacy/src/app/polyfills.js
  // Core js only adds these polyfills when browser doesn't have the native version
  await import('core-js/modules/es.object.has-own');
  await import('core-js/modules/es.global-this');
}());

// TODO: update babel packages that supports top level await
// $FlowFixMe
// await import('core-js/modules/es.object.has-own');
